import { navigate } from 'gatsby'
import React, { useEffect } from 'react'

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
  </>
)

export default () => {
  useEffect(() => {
    navigate(
      `https://docs.google.com/forms/d/e/1FAIpQLSfo3P5Nk2nWf4TPiSF5JrtzIvkSwn2tl-aWAHOtPFzmyoj39w/viewform`
    )
  }, [])
  return null
}
